.fade-in-animation {
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

.invisible {
  opacity: 0;
}

.swipe-from-right {
  animation: swipeFromRightAnimation ease 2s;
}

.swipe-from-left {
  animation: swipeFromLeftAnimation ease 2s;
}
.slide-up-animation {
  animation: slide-up ease 2s;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

@keyframes swipeFromLeftAnimation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipeFromRightAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

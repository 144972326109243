.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px 60px;
}

.services-title {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  color: #3ab654;
  margin: 0 0 16px;
  letter-spacing: 3.5px;
  transition: all ease 2s;
  transform: scale(0.7);
}

.services-heading {
  text-align: center;
  font-size: 2.6rem;
  line-height: 1.76;
  margin: 0 0 10px;
  font-weight: 700;
  color: #000000;
  letter-spacing: -0.5px;
  transition: all ease 2s;
  transform: scale(0.7);
}

.services-shrink {
  transform: scale(1);
}

@media screen and (min-width: 1024px) {
  .services-container {
    margin: 0 200px 150px;
  }
  .services-heading {
    font-size: 3.6rem;
    line-height: 1.3;
    margin: 0 0 80px;
  }
}

.nav-list {
  display: none;
  margin: 0;
  line-height: 2.2;
  list-style: none;
  font-size: 2.4rem;
  font-weight: 600;
  color: #4E4E4E;
}

.menu-hamburger {
  background: none;
  border: 0;
}

.nav-item {
  font-size: 1.6rem;
  line-height: 1.6;
  cursor: pointer;
  text-decoration: none;
  color: #4E4E4E;
}

.nav-item:not(:last-child) {
  margin-bottom: 30px;
}

.global-header-nav-link {
  color: #3AB654;
  cursor: pointer;
  text-decoration: none;
}

.column-view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  padding: 39px 53px 39px 34px;
}

.menu-cross {
  align-self: flex-start;
}

/* .header-nav-link {
  cursor: pointer;
  text-decoration: none;
  color: #4E4E4E;
} */

@media screen and (min-width: 1024px) {
  .nav-list {
    display: flex;
  }

  .nav-item:not(:last-child) {
    margin: 0 43px 0 0;

  }
}
.footer {
  display: flex;
  padding: 0 25px;
  flex-direction: column-reverse;
}

@media screen and (min-width: 1024px) {
  .footer {
    padding: 0;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 98px;
  }
}
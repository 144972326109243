.slider-container {
  position: relative;

}

.hero-text-wrapper {
  margin: 0;
  display: block;
  animation: swipeFromTopHero ease 1s;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slide-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.dots {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
}

.carousel-text {
  position: absolute;
  margin: 0;
  font-size: 5.1rem;
  line-height: 1.3;
  letter-spacing: 1.3px;
  top: 24%;
  left: 13%;
  width: 55%;
}

.hero-text-highlight {
  font-style: normal;
  color: #3ab654;
  font-weight: 700;
  font-size: 5.8rem;
  letter-spacing: 2px;
}

.hero-text-title {
  font-style: normal;
  font-size: 4.8rem;
  color: #3ab654;
  font-weight: 700;
}

.hero-text-description {
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
}

.slide-wrapper:nth-child(3)>.carousel-text {
  width: 45%;
  font-size: 4.8rem;
  line-height: 1.1;
}

.slide-wrapper:nth-child(2n)>.carousel-text {
  left: 50%;
  top: 25%;
  width: 42%;
  letter-spacing: 0;
  line-height: 1.1;
}

.slide-wrapper:nth-child(2)>.carousel-text>.hero-text-description {
  font-size: 2.8rem;
  line-height: 1;
}



@media screen and (max-width:1024px) {
  .slide-image {
    object-fit: cover;
  }

  .carousel-text {
    font-size: 2.8rem;
    line-height: 1.3;
    letter-spacing: 1.3px;
    top: 21%;
    left: 10%;
    width: 83%;
  }

  .hero-text-highlight {
    font-size: 3.4rem;
  }

  .hero-text-title {
    font-size: 2.8rem;
  }

  .slide-wrapper:nth-child(2n)>.carousel-text {
    left: 8%;
    top: 20%;
    width: 86%;
    letter-spacing: 0;
    line-height: 1.2;
    font-size: 1.6rem;
  }

  .slide-wrapper:nth-child(3)>.carousel-text {
    width: 79%;
    font-size: 3rem;
    top: 21%;
    left: 10%;
  }

  .hero-text-description {
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 500;
  }

}

@keyframes swipeFromTopHero {
  0% {
    transform: translateY(-100%);
    opacity: 0;

  }

  100% {
    transform: translateY(0);
    opacity: 1;

  }
}
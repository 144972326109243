.slider-container {
  position: relative;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.arrow {
  background: none;
  user-select: none;
  color: #3ab654;
  font-size: 4rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
}

.arrow:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 1024px) {
  .arrow {
    font-size: 6rem;
  }
}

.flip-card:not(:last-child) {
  margin-bottom: 60px;
}

@media screen and (max-width: 1024px) {
  .flip-card {
    background: linear-gradient(0deg, #ffffff 50%, rgba(58, 182, 84, 0.5) 100%);
    border: none;
    border-radius: 30px;
    padding: 50px 20px;
    box-shadow: 1px 1px 5px rgba(58, 182, 84, 0.5);
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (min-width: 1024px) {
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px;
    border: none;
    font-family: "Poppins", sans-serif;
  }
  .flip-card:nth-child(n) {
    margin: 0;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 30px;
  }
  .animate {
    animation-name: flip;
    animation-duration: 10s;
    animation-iteration-count: 1;
  }

  @keyframes flip {
    30% {
      transform: rotateY(180deg);
    }
    80% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }

  /* .flip-card:hover .flip-card-inner {
} */

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px rgba(58, 182, 84, 0.5);
    font-family: "Poppins", sans-serif;
  }

  .flip-card-front {
    background-color: #ffffff;
  }

  .flip-card-back {
    background: linear-gradient(0deg, #ffffff 50%, rgba(58, 182, 84, 0.5) 100%);
    transform: rotateY(180deg);
  }
}

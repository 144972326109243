.logo {
  width: 228px;
  height: 68px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0;
}
.value-section {
  text-align: center;
  padding: 37px 37px 67px;
  margin: 0 20px 60px;
  border-radius: 20px;
  box-shadow: 2px 2px 8px #d4d4d4;
  background-color: #f9f9f9;
}

.value-title-section {
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 10px;
  margin: 0;
  line-height: 1.75;
}

.first-text {
  font-weight: 400;
  font-style: normal;
  display: block;
}

.value-description {
  font-weight: 500;
  line-height: 1.3;
  font-size: 1.6rem;
  margin: 0 0 40px;
  letter-spacing: 0;
}

.value-subtitle-section {
  color: #3ab654;
  font-style: normal;
}

@media screen and (min-width: 1024px) {
  .value-section {
    padding: 50px 0;
    margin: 0 150px 150px;
  }

  .value-title-section {
    padding: 0;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  .first-text {
    display: inline-block;
  }

  .value-subtitle-section {
    color: #3ab654;
    font-style: normal;
  }

  .value-description {
    padding: 0 150px;
    font-size: 2rem;
    margin-bottom: 65px;
  }
}
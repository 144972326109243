.dots-container {
  display: flex;
  justify-content: space-between;
  width: 58px;
}
.circle {
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-sizing: border-box;
}
.solid {
  border: 1px solid #33af4d;
  background-color: #33af4d;
}

.servicelist-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 5px 8px 30px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  padding: 0;
}
.service-icon-btn {
  width: 297px;
  padding: 31px 0;
  border: none;
  border-bottom: 2px solid rgba(78, 78, 78, 0.1);
  background-color: #ffffff;
}
.service-active {
  border-bottom: 2px solid #3ab654;
}

.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}

@media screen and (min-width: 1024px) {
  .servicelist-container {
    padding: 64px 0 0;
  }
  .service-icon-btn {
    padding: 0 0 50px;
  }
  .order-1 {
    /* order is 0 always in desktop to not to change sequence */
    order: 0;
  }
}

.home-gradient {
  position: relative;
  margin: 0;
  overflow-x: hidden;
}

.about-gradient-circle {
  position: absolute;
  z-index: -1;
  top: 13%;
  left: 16%;
}

.team-gradient-circle {
  display: none;
}

.benefits-gradient-circle {
  display: none;
}

@media screen and (min-width: 1024px) {
  .about-gradient-circle {
    top: 17%;
    left: 63%;
  }

  .team-gradient-circle {
    display: block;
    position: absolute;
    z-index: -1;
    top: 36%;
    left: -23%;
  }

  .benefits-gradient-circle {
    display: block;
    position: absolute;
    z-index: -1;
    top: 63.5%;
    left: 62%;
  }
}
/* Blogs.css */
.blogs-heading {
  font-weight: 100;
  font-style: italic;
  margin: 30px 0px;
}
.blog-container {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  gap: 60px;
}

.left-section {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.author-info {
  background-color: #4caf50;
  border-radius: 10px;
  padding: 30px;
  color: #f0f0f0;
}

.author-info h3 {
  font-size: 23px;
  margin: 0px;
}
.author-des p {
  font-size: 12px;
}
.author-img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.author-des {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.author-text {
  border-top: 1px solid rgba(255, 255, 255, 0.337);
  padding: 10px 0px;
}
.blog-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  text-transform: uppercase;
}
.blog-caption h1 {
  margin: 0px;
}
.blog-img {
  width: 100%;
  border-radius: 10px;
}

.right-section {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.tabs {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
}
.tabs i {
  font-style: italic;
  font-size: 14px;
}
.tabs p {
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  cursor: pointer;
  text-align: left;
  font-size: 15px;
  font-style: italic;
}

.tabs p.active {
  border-left: #4caf50 4px solid;
  color: #4caf50;
  padding-left: 10px;
}

.blog-content .blog-section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: "Afacad", sans-serif;
  font-weight: 100;
}
.blog-section {
  background: #f9f9f9;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Section Headers */
.blog-section h2 {
  font-size: 27px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #4caf50;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

/* Paragraphs */
.blog-section p {
  font-size: 20px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Bold Text */
.blog-section b {
  color: #4caf50;
  font-weight: 600;
}

/* Unordered List Styling */
.blog-section ul {
  list-style-type: none;
  padding-left: 0;
}

/* List Items */
.blog-section ul li {
  background: #fff;
  padding: 12px;
  margin: 10px 0;
  border-left: 4px solid #4caf50;
  border-radius: 6px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  color: #444;
}

/* Links */
.blog-section a {
  display: inline-block;
  color: #4caf50;
  font-weight: bold;
  text-decoration: none;
  margin-top: 10px;
}

.blog-section a:hover {
  text-decoration: underline;
  color: #56c669;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-container {
    flex-direction: column;
  }
  .left-section,
  .right-section {
    width: 100%;
  }
  .tabs {
    flex-wrap: wrap;
  }
}

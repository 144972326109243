.openings-section {
  padding: 96px 0 99px;
  margin-bottom: 60px;
  background-color: #f9f9f9;
}

.openings-head-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 455px 47px;
  transform: scale(0.7);
  transition: all ease 1.5s;
}

.openings-theme {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #001833;
  margin: 0 0 12px;
}

.openings-title {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.5;
  color: #001833;
  margin: 0 0 12px;
}

.openings-description {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
  margin: 0;
  text-align: center;
}

.openings-wrapper {
  margin: 0 271px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.job-opening {
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  background-color: white;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 26px 20px 12px;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 1.5;
  transition: all ease-in-out 0.5s;
}
.job-opening:hover {
  transform: scale(1.05);
}
.opening-role {
  flex: 1;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
}

.opening-details-wrapper {
  flex: 1;
  margin: 0;
}

.columns-2 {
  columns: 2;
  column-gap: 110px;
}

.opening-heading {
  color: #d4d4d4;
  font-size: 1.6rem;
}

.opening-value {
  font-size: 2.4rem;
  color: #001833;
}
.opening-btn {
  margin: 0 0 0 20px;
}

.opening-heading,
.opening-value {
  margin: 0;
}

.openings-grow-txt {
  transform: scale(1);
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .openings-section {
    padding: 60px 0 50px;
    margin: 0 15px 30px;
  }

  .openings-head-section {
    margin: 0 20px 60px;
    text-align: center;
  }
  .opening-value {
    font-size: 1.6rem;
  }
  .openings-wrapper {
    margin: 0 20px;
  }
  .opening-btn {
    margin: 10px 0 0;
  }
  .columns-2 {
    column-gap: 20px;
  }

  .job-opening {
    flex-direction: column;
  }

  .opening-role {
    padding-bottom: 5px;
    text-align: center;
  }

  .openings-theme {
    margin: 0 0 5px;
  }

  .openings-title {
    margin: 0 0 5px;
  }

  .opening-heading:nth-child(3) {
    text-align: center;
  }
}

.btn {
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.5s;
}

.primary {
  border: 2px solid #3ab654;
  background-color: #3ab654;
}
.primary:hover {
  border: 2px solid #3ab654;
  background-color: #ffffff;
  color: #3ab654;
  transform: scale(1.05);
}

.outline {
  border: 2px solid #ffffff;
  background-color: transparent;
}

.outline:hover {
  border: 2px solid #3ab654;
  color: #3ab654;
  border: 2px solid #3ab654;
  transform: scale(1.05);
}
.link {
  background: none;
  border: none;
  color: #3ab654;
}
.team-container {
  margin: 0 42.5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.team-title {
  font-size: 2.6rem;
  margin: 0 0 10px;
  line-height: 1.5;
  padding: 0 57.5px;
  letter-spacing: -0.2px;
  transition: all ease 1.5s;
  transform: scale(1.3);
}
.team-shrink-font {
  transform: scale(1);
}
.team-text {
  color: #3ab654;
  font-style: normal;
  display: block;
}

.team-text-color {
  color: #3ab654;
  font-style: normal;
  font-weight: 700;
}

.description {
  line-height: 1.625;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0 0 10px;
  color: #4e4e4e;
  padding: 0;
  letter-spacing: -0.1px;
}

.shape-text {
  font-weight: 700;
  font-style: normal;
  color: #4e4e4e;
  font-size: 2.3rem;
  line-height: 1.1;
  letter-spacing: 0;
  transition: letter-spacing ease-in 1.5s;
}
.shape-text.team-compress-font {
  letter-spacing: 0;
}
.team-image {
  margin-top: -15%;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .team-container {
    margin: 0 210.5px;
  }

  .team-title {
    font-size: 3.6rem;
    line-height: 1.4;
    margin-bottom: 45px;
  }

  .description {
    letter-spacing: 0;
    margin-bottom: 25px;
  }

  .team-image {
    margin-top: -10%;
  }
  .shape-text {
    letter-spacing: 5px;
  }
}

.info-section {
  display: flex;
  flex-direction: column;
  
}

.reverse {
  flex-direction: column-reverse;
}

@media screen and (min-width: 1024px) {
  .info-section {
    flex-direction: row;
  }

  .reverse {
    flex-direction: row-reverse;

  }
}
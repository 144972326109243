.about-image-wrapper {
  position: relative;
}
.greenDot-position {
  display: none;
  position: absolute;
  z-index: 2;
  top: 62%;
  left: 0;
  transform: translateX(-50%);
}

.about-section {
  padding: 0 47.5px 0;
  margin-bottom: 60px;
  position: relative;
}

.about-content {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
  align-items: flex-start;
}

.about-title {
  font-weight: 700;
  color: #000000;
  font-size: 2.6rem;
  line-height: 1.2;
  margin: 0 0 10px;
}

.about-para {
  font-weight: 400;
  color: #4e4e4e;
  font-size: 1.6rem;
  line-height: 1.625;
  margin: 0 0 10px;
  align-items: right;
}

.about-text-highlight {
  color: #3ab654;
  font-style: normal;
}

.about-button {
  padding: 10px 47px;
  border-radius: 9px;
  background-color: #3ab654;
  font-size: 1.4rem;
  color: #ffffff;
  border: 0;
}

.about-image {
  width: 100%;
  margin: 0;
}

.about-checklist-ornament {
  width: 60px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
}

/* width: 100%;
  height: 721px; */

@media screen and (min-width: 1024px) {
  .about-image {
    width: 509px;
    height: 501px;
    margin: 0;
    padding: 0;
  }

  .about-checklist-ornament {
    width: 98px;
    height: 149px;
  }

  .about-section {
    max-width: 1075px;
    align-items: flex-end;
    padding: 0;
    margin: 0 auto 76px;
  }

  .about-content {
    padding-right: 29px;
    margin: 0;
  }

  .about-title {
    font-size: 4.8rem;
    line-height: 1.2;
    margin-bottom: 13px;
    letter-spacing: -1px;
  }

  .about-para {
    margin-bottom: 59px;
  }

  .about-button {
    font-size: 1.6rem;
    line-height: 1.6;
    padding: 11px 54px;
    border-radius: 10px;
    margin-bottom: 7px;
  }
  .greenDot-position {
    display: block;
    position: absolute;
    z-index: 2;
    top: 62%;
    left: 0;
    transform: translateX(-50%);
  }
}

.home-navbar-container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 36px 30px 0;
}

.header-container {
  margin-bottom: 28px;
}


@media screen and (min-width: 1024px) {
  .home-navbar-container {
    margin: 35px 122px 0 100px;
  }

  .header-container {
    margin-bottom: 76px;
  }
}
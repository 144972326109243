.careers-navbar {
  margin: 30px;
}

.join-us-gradient-circle {
  position: absolute;
  z-index: -1;
  top: 46%;
  left: -69%;
}

@media screen and (min-width : 1024px) {
  .careers-navbar {
    margin: 20px 121px 20px 116px;
  }

  .join-us-gradient-circle {
    top: 51%;
    left: -19%;
  }

}
.flip-card-container {
  display: flex;
  flex-direction: column;
}
.flip-back-description {
  font-weight: 400;
  font-size: 1.6rem;
  margin: 0 15px;
}
.flip-front-title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 4.6rem;
  margin: 0;
}

@media screen and (min-width: 1024px) {
  .flip-card-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 0 40px;
    margin-bottom: 40px;
  }

  .flip-back-description {
    font-weight: 400;
    font-size: 1.6rem;
  }
  .flip-front-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 4.6rem;
  }
}

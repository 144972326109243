.joinus-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/join_us_bg.png");
  background-size: cover;
  height: 550px;
  background-position: center;
  color: #ffffff;
  line-height: 1.5;
  margin-bottom: 60px;
  margin-top: 0;
  padding: 0 20px;
}

.joinus-title {
  display: block;
  font-size: 3.6rem;
  margin: 0 0 8px 0;
  font-weight: 500;
  text-align: center;
}

.joinus-description {
  font-size: 1.6rem;
  text-align: center;
  margin: 0 0 36px 0;
  font-weight: 400;
}

.joinus-btn {
  width: 140px;
  height: 40px;
}

.joinus-btn:first-child {
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .joinus-container {
    background-size: 1920px;
    margin-bottom: 78px;
  }

  .joinus-title {
    margin: 0 0 12px 0;
  }

  .joinus-description {
    padding: 0 345px;
  }

}
.serviceinfo-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.serviceinfo-text-wrapper {
  margin: 0 37px 31px;
  flex-direction: column;
}
.serviceinfo-title {
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 700;
  color: #000000;
  margin: 31px 0 10px;
}
.serviceinfo-description {
  font-size: 1.6rem;
  line-height: 1.625;
  font-weight: 400;
  color: #4e4e4e;
  margin: 0;
}
.service-image {
  width: 356px;
  height: 343px;
  margin: 0 auto;
  border-radius: 20%;
}

@media screen and (min-width: 1024px) {
  .service-image {
    width: 500px;
    height: 500px;
    border: 20px solid transparent;
    box-sizing: border-box;
  }
  .serviceinfo-text-wrapper {
    margin: 0 47px 0 77px;
  }
  .serviceinfo-title {
    margin: 41px 0 10px;
  }
  .serviceinfo-description {
    margin-bottom: 27px;
  }
}

.joinusBenefits-image-background {
  text-align: center;
  background-color: #3ab65466;
  width: 74px;
  height: 74px;
  border-radius: 15px;
  margin: 0 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinusBenefits-container {
  align-items: flex-start;
  margin: 0 10px 60px;
}

.joinusBenefits-section {
  text-align: center;
  flex: auto;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.joinusBenefits-title {
  color: #001833;
  font-weight: 500;
  line-height: 1.5;
  font-size: 1.8rem;
  margin: 0 0 12px;
}

.joinusBenefits-subtitle {
  color: #001833;
  font-weight: 700;
  line-height: 1.2;
  font-size: 2.8rem;
  margin: 0 0 9px;
}

.joinusBenefits-description {
  font-weight: 400;
  color: #001833;
  line-height: 1.5;
  font-size: 14px;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.34px;
}

.joinusBenefits-rightContainer {
  row-gap: 42px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  flex: 0 0 52.6%;
}

.joinus-benfits-item {
  flex: 0 0 50%;
  margin: 0;
  text-align: center;
}

.joinusBenefit-title {
  font-weight: 700;
}

.joinusBenefits-image-background {
  background-color: rgba(58, 182, 84, 0.4);
  width: 74px;
  height: 74px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
}

.joinusBenefits-description-right {
  margin: 0;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 300;
}

.joinus-benefit-title {
  margin: 0 0 8px 0;
  font-size: 18px;
  line-height: 1.5;
}

@media screen and (min-width: 1024px) {
  .joinusBenefits-container {
    margin: 0 161px 60px;
  }

  .joinusBenefits-section {
    flex: 0 0 47.4%;
    padding-right: 69px;
    text-align: left;
    margin: 0;
  }

  .joinus-benfits-item {
    text-align: left;
    flex-basis: calc(50% - 5px);
  }

  .joinusBenefits-image-background {
    margin: 0 0 30px;
  }

  .joinusBenefits-subtitle {
    font-weight: 700;
    line-height: 1.5;
    font-size: 3.6rem;
  }
  .joinusBenefits-rightContainer {
    column-gap: 10px;
  }
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
  /* scrollbar-gutter: stable both-edges; */
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  scrollbar-gutter: stable;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }
}

.benefit-section {
  margin: 60px 29px;
}

.benefits-container {
  margin-top: 0;
}

.benefit-content-0,
.benefit-content-1 {
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: flex-start;
}

.benefit-title {
  font-weight: 700;
  color: #000000;
  font-size: 2.8rem;
  line-height: 1.5;
  margin: 0 0 10px;
}

.benefit-image-male,
.benefit-image-female {
  width: 100%;
  object-fit: contain;
}
.benefit-image-female {
  transform: translateX(-18px);
}

.benefit-text-highlight {
  color: #3ab654;
  font-style: normal;
}

.benefit-para {
  font-weight: 400;
  color: #4e4e4e;
  font-size: 1.6rem;
  line-height: 1.625;
  margin: 0 0 10px;
}

.benefit-learn-more {
  font-size: 1.6rem;
  line-height: 1.625;
  font-weight: 700;
  padding: 0;
}

@media screen and (min-width: 1024px) {
  .benefit-image-male {
    width: 483px;
    height: 563px;
  }

  .benefit-image-female {
    width: 815px;
    height: 570px;
    transform: none;
  }

  .benefits-container {
    margin: 0 10% 114px;
  }

  .benefit-section {
    flex-direction: row-reverse;
    margin: 0;
  }

  .benefit-section:nth-child(2n) {
    flex-direction: row;
  }

  .benefit-content-0 {
    display: inline-flex;
    flex-direction: column;
    margin: 100px 0 0 152px;
  }

  .benefit-content-1 {
    margin: 100px 0 0 0;
  }

  .benefit-title {
    font-size: 4.8rem;
    line-height: 1.2;
    margin-bottom: 32px;
    letter-spacing: -1px;
    margin-top: 0;
  }

  .benefit-para {
    width: 385px;
    margin-bottom: 25px;
    padding-bottom: 0;
    align-self: flex-start;
  }
}

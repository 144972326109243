.contact-container {
  display: flex;
  flex-direction: column;
  margin: 60px auto 0;
}

.footer-logo {
  width: 240px;
  height: 70px;
  margin-bottom: 22px;
}

.address {
  font-size: 1.2rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 22px;
  line-height: 1.5;
}

.media {
  margin-bottom: 40px;
}

.footer-heading {
  letter-spacing: 4.5px;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #3ab654;
  display: inline-block;
  margin: 0 0 16px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 0 12px;
}

.footer-nav-item {
  list-style: none;
  font-size: 1.6rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
}

.footer-nav-item:not(:last-child) {
  margin-right: 14px;
}

.copy-rights {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 98px;
  color: #4e4e4e;
}

.footer-nav-link {
  text-decoration: none;
}

.media-links {
  display: flex;
  column-gap: 14px;
}

.external-link {
  text-decoration: none;
  color: #000000;
}

@media screen and (min-width: 1024px) {
  .contact-container {
    margin: 0;
  }

  .footer-logo {
    width: 300px;
    height: 90px;
    margin-bottom: 12px;
  }

  .address {
    margin-bottom: 12px;
  }

  .media {
    margin-bottom: 50px;
  }

  .footer-nav-item:not(:last-child) {
    margin-right: 24px;
  }

  .copy-rights {
    margin-bottom: 0;
  }
}
